@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

img {
  max-height: 100%;
}

h2 {
  @apply text-primaryColorDark mb-2 text-3xl mt-4 font-bold;
}

.btn-primary,
.btn-secondary {
  @apply px-2 md:px-3 py-1 rounded-md text-xs md:text-base text-white font-medium shadow-md shadow-primaryColorLight;
}

.btn-primary:hover,
.btn-secondary:hover {
  @apply scale-105;
}

.btn-primary {
  @apply bg-primaryColor hover:bg-primaryColorDark;
}

.btn-secondary {
  @apply bg-primaryTextColor hover:bg-primaryTextColorDark;
}
